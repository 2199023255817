import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['recurringBaseStandard', 'serviceFeeDcStandard', 'serviceFeeOwnerStandard', 'monthlyRecurringTotalStandard', 'recurringBaseConditional', 'serviceFeeDcConditional', 'serviceFeeOwnerConditional', 'monthlyRecurringTotalConditional']

  sumMonthlyRecurringTotalStandard() {
   var recurringBaseValue = parseFloat($(this.recurringBaseStandardTarget).val())
   var serviceFeeDcValue =  parseFloat($(this.serviceFeeDcStandardTarget).val())
   var serviceFeeOwnerValue =  parseFloat($(this.serviceFeeOwnerStandardTarget).val())

   var recurringBaseValue = isNumber(recurringBaseValue) ? recurringBaseValue : 0.00
   var serviceFeeDcValue = isNumber(serviceFeeDcValue) ? serviceFeeDcValue : 0.00
   var serviceFeeOwnerValue =  isNumber(serviceFeeOwnerValue) ? serviceFeeOwnerValue : 0.00

   $(this.monthlyRecurringTotalStandardTarget).val((recurringBaseValue + serviceFeeDcValue + serviceFeeOwnerValue).toFixed(1))
  }

  sumMonthlyRecurringTotalConditional() {
   var recurringBaseValue = parseFloat($(this.recurringBaseConditionalTarget).val())
   var serviceFeeDcValue =  parseFloat($(this.serviceFeeDcConditionalTarget).val())
   var serviceFeeOwnerValue =  parseFloat($(this.serviceFeeOwnerConditionalTarget).val())

   var recurringBaseValue = isNumber(recurringBaseValue) ? recurringBaseValue : 0.00
   var serviceFeeDcValue = isNumber(serviceFeeDcValue) ? serviceFeeDcValue : 0.00
   var serviceFeeOwnerValue =  isNumber(serviceFeeOwnerValue) ? serviceFeeOwnerValue : 0.00


   $(this.monthlyRecurringTotalConditionalTarget).val((recurringBaseValue + serviceFeeDcValue + serviceFeeOwnerValue).toFixed(1))
  }


}
function isNumber(n) {
  return Number(n) === n
}